import axios, { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

type FormInputs = {
  email: string;
  message: string;
  captchaToken: string;
};

type ContactResponse = {
  field?: 'email' | 'message' | 'captchaToken';
  message: string;
};

const ContactForm = (): React.ReactElement => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    setValue,
    setError,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      email: '',
      message: '',
      captchaToken: '',
    },
  });
  register('captchaToken', { required: 'Valid reCAPTCHA is required' });

  const onReCAPTCHAChange = (captchaToken: string | null): void => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaToken) {
      return;
    }
    setValue('captchaToken', captchaToken);
    clearErrors('captchaToken');
  };

  const onSubmit = (formData: FormInputs): void => {
    setLoading(true);
    axios
      .post<ContactResponse>('/api/contact', formData)
      .then(function () {
        // reset form to default values
        reset();
      })
      .catch(function (error: AxiosError<ContactResponse>) {
        setError('captchaToken', { message: 'Oops! Something went wrong' });
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          // handle error
          if (data?.field) {
            setError(data.field, { message: data.message });
          } else if (data?.message) {
            setError('captchaToken', { message: data.message });
          }
        }
      })
      .then(function () {
        // finally
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setLoading(false);
      });
  };

  return (
    <div id="contact">
      <h3 className="text-5xl text-white font-bold pb-12">Contact</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-6">
          <label className="block">
            <span className="text-gray-100 font-bold">Email address</span>
            <input
              type="email"
              className="
                    h-14
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-white
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              placeholder="john.doe@example.com"
              {...register('email', {
                required: 'Email address is required',
              })}
            />
            {errors.email?.message && <p className="pt-1 text-red-400">{errors.email?.message}</p>}
          </label>
          <label className="block">
            <span className="text-gray-100 font-bold">Message</span>
            <textarea
              className="
                    h-28
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-white
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  "
              {...register('message', { required: 'Message is required' })}
            />
            {errors.message?.message && (
              <p className="pt-1 text-red-400">{errors.message?.message}</p>
            )}
          </label>

          <div className="">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="compact"
              sitekey="6Ld6-40eAAAAADYxPH4C7QAF2HDhYfBPhGh13Mv6"
              onChange={onReCAPTCHAChange}
            />
            {errors.captchaToken?.message && (
              <p className="text-red-400">{errors.captchaToken?.message}</p>
            )}
          </div>
          <button
            className="h-14 border w-1/2 mx-auto hover:text-white hover:scale-110 transition duration-150 ease-in-out rounded-full bg-transparent text-gray-100"
            disabled={loading}
            type="submit">
            {loading ? 'Loading...' : 'Send'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
