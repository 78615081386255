import type { NextPage } from 'next';
import Head from 'next/head';

import ContactForm from 'src/components/ContactForm';

const Home: NextPage = () => {
  return (
    <div className="font-sans">
      <Head>
        <title>Graham Lighthouse</title>
        <meta name="description" content="We help build software that makes a difference" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="icon" type="image/png" href="/favicon.png" />
      </Head>
      <nav>
        <div className="py-4 px-6 bg-white">
          <div className="flex items-center">
            <div className="flex-1">
              <h1 className="text-2xl font-bold leading-snug text-gray-800 tracking-normal uppercase text-left">
                Graham Lighthouse
              </h1>
            </div>
            <div className="flex-1 text-right">
              <a href="#contact" className="tracking-wider uppercase text-sm">
                Contact
              </a>
            </div>
          </div>
        </div>
      </nav>

      <main>
        <div className="py-36 px-4 bg-gradient-to-r from-[#f0c27b] to-[#4b1248] text-center">
          <div className="mx-auto max-w-2xl">
            <h1 className="text-5xl pb-14 font-bold text-white leading-snug">
              We help build <span className="text-yellow-300">software</span> that makes a
              difference
            </h1>
          </div>
          <div className="mx-auto max-w-xl">
            <h2 className="text-xl text-gray-100 font-light leading-snug tracking-wider">
              Boost your business by focusing on what your customer actually needs. Let us help you
              find the right solution!
            </h2>
          </div>
        </div>

        {/* Technology */}
        <div className="max-w-4xl mx-auto">
          <div className="pt-24 pb-8 lg:pt-36 lg:pb-12 grid lg:grid-cols-3 grid-cols-1">
            <div className="px-6 pb-16 lg:pb-24 mx-auto">
              <img src="/logo-python.svg" alt="Python" className="h-16" />
            </div>
            <div className="px-6 pb-16 lg:pb-24 mx-auto">
              <img src="/logo-django.svg" alt="Django" className="h-16" />
            </div>
            <div className="px-6 pb-16 lg:pb-24 mx-auto">
              <img src="/logo-ts.svg" alt="TypeScript" className="h-16" />
            </div>
            <div className="px-6 pb-16 lg:pb-24 mx-auto">
              <img src="/logo-react.svg" alt="React" className="h-16" />
            </div>
            <div className="px-6 pb-16 lg:pb-24 mx-auto">
              <img src="/logo-docker.svg" alt="Docker" className="h-16" />
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div className="px-4 bg-gradient-to-t from-[#24c6dc] to-[#514a9d]">
          <div className="py-24 max-w-xl mx-auto">
            <ContactForm />

            <div className="py-24 max-w-4xl mx-auto">
              <h3 className="text-5xl text-white font-bold pb-12">Address</h3>
              <ul className="text-gray-100 text-xl">
                <li className="font-bold">Graham Lighthouse Ltd</li>
                <li>Suite 4.3.02, Block 4, Eurotowers</li>
                <li>Gibraltar</li>
                <li>GX11 1AA</li>
                <li>Gibraltar</li>
              </ul>
            </div>
          </div>

          <div className="pb-4 text-gray-100 text-sm">
            <p>© 2022 Graham Lighthouse Ltd. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
